<template>
    <div>
        <tool-interface/>
    </div>
</template>

<script>
import ToolInterface from './ToolInterface';

export default {
    name: 'Home',
    components: { ToolInterface }
};

</script>
<style scoped>

</style>
